@import '~@ebay/skin/tokens.css';
@import '~@ebay/skin/less.less';
.parts-installer {
  width: 100%;

  div.installer-list {
    height: ~"calc(90% - 80px)";
  }

  // skin override
  .drawer-dialog__window {
    .drawer-dialog__header {
      .drawer-dialog__close {
        display: none;
      }
    }
    .drawer-dialog__main {
      .inline-notice {
        .inline-notice__title {
          .typography-regular();
          margin: 0;
        }
      }
      .installer-list {
        .installer-card-no-radio__right {
          .installer-card-no-radio__name {
            .typography-regular();
          }
          .installer-card-no-radio__address {
            span:first-child {
              .typography-regular();
              font-weight: 400;
            }
          }
        }
      }
    }
    .drawer-dialog__footer {
      display: none;
    }
  }
  .drawer-dialog__window--expanded {
    height: 90%;
    max-height: 90%;
  }

  &__form-fields,
  &__form-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__form-fields {
    button.sort__icon {
      min-width: 100px;
      height: 30px;
      text-align: left;
      background-color: transparent;

      svg {
        padding-right: 5px;
      }
    }
  }

  &__form-installation-label {
    padding: 0 5px;
  }

  &__fake-drawer-peek {
    background-color: var(--color-background-primary);
    width: 100%;
    height: 55px;
    z-index: 20;
    position: fixed;
    bottom: 0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    text-align: center;
    button {
      background-color: initial;
      border: none;
      left: 0;
      margin: 0 auto;
      padding: 8px;
      position: relative;
      right: 0;
      top: 5px;
      z-index: 2;
      &::after {
        background-color: var(--color-stroke-default);
        border-radius: 3px;
        content: "";
        display: block;
        height: 2px;
        width: 24px;
      }
    }
  }

  .arrow-tooltip {
    bottom: 40px;
    top: 64px;
  }
  &-loading {
    .screen-loading {
      padding: 16px;
      &__content-main {
        flex-direction: column;
      }
      &__content-left {
        .skeleton {
          padding-bottom: 16px;
        }
        .skeleton__image {
          height: 225px;
        }
      }
      &__content-right {
        .skeleton {
          padding-bottom: 16px;
        }
      }
      &__content-right > div {
        div:nth-child(1) .skeleton__image {
          height: 300px;
        }
        div:nth-child(2) .skeleton__image {
          height: 200px;
        }
        div:nth-child(3) .skeleton__image {
          height: 100px;
        }
      }
      &__content-footer {
        .skeleton__textbox {
          height: 50px;
        }
      }
    }
  }

  .map-header {
    position: absolute;
    width: ~"calc(100vw - 32px)";
    z-index: 1;
    background-color: white;
    padding: 0;
  }
}