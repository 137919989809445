@import './common.less';

.whats-included {
  margin: 16px 0;

  &__title {
    position: relative;

    .typography-regular-bold();
  }

  &__details {
    summary.details__summary {
      display: flex;
      justify-content: space-between;
    }

    .whats-included__see_more {
      font-size: @font-size-14;
      margin-top: 10px
    }
  }
}
