.vas-warranty-spoke-m {
  padding: 0.25rem 1rem 0.25rem 1rem;

  .vas-spoke-header {
    display: flex;
    flex-shrink: 0;
    position: relative;
    align-items: center;
    border-bottom: 1px solid #eee;

    &-text {
      flex: 1 1 auto;
      font-size: 1.125rem;
      font-weight: 400;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 0.5rem;
      white-space: nowrap;
    }

    &-close-btn {
      height: 40px;
      width: 40px;
      border: none;
      background-color: transparent;

      svg.icon.icon--close-16 {
        fill: #555;
      }
    }
  }

  .warranty-wrapper {
    font-size: 14px;
    line-height: 1.29;
  }

  .warranty-item-card {
    margin-top: 1rem;
    display: flex;
    color: #333;
    align-items: center;

    &__img {
      margin-right: 1rem;

      img {
        max-width: 6rem;
        min-width: 3rem;
      }
    }
    &__title {
      font-size: 14px;
      align-self: center;
    }
  }

  .warranty-details-item {
    margin-top: 1rem;
  }

  .warranty-details-item-title {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.25;
    color: #333;
    letter-spacing: 0.2px;
    display: flex;
    flex-direction: column;

    span {
      margin-bottom: 8px;
    }

    // TODO: Can <ux-textspans/> give unique classes to text-display with multiple textspans?
    span:nth-child(1) {
      font-weight: 500;
    }

    span:nth-child(2) {
      color: #767676;
      font-size: 13px;
      line-height: 1.18;
      letter-spacing: 0.2px;
      margin-bottom: 8px;
    }

    span:nth-child(3) {
      font-size: 20px;
      line-height: 1.2;
      color: #333;
      letter-spacing: 0.3px;
    }
  }

  .warranty-details-body {
    display: flex;
    flex-direction: column;

    &__item {
      padding-top: 16px;
      line-height: 1.35;
      margin-left: 8px;
    }
  }

  .warranty-learn-more {
    margin-top: 0.75rem;
    text-decoration: underline;

    a,
    a:focus,
    a:visited {
      color: #3665f3;
    }
  }

  .warranty-details-group {
    border: 1px solid red;
  }

  .warranty-actions {
    margin: 1rem 0 1rem 0;

    button.btn.btn--fluid {
      border-color: #ccc;
      border-radius: 3px;
      border-style: solid;
      border-width: 1px;
      font-family: inherit;
      font-size: 16px;
      font-weight: normal;
      height: 48px;
      margin-bottom: 8px;
      padding: 0 48px;
      overflow: hidden;
      text-overflow: ellipsis;

      &.btn--primary {
        background-color: #0051ae;
      }

      &.btn--secondary {
        background-color: #fff;
        color: #0654ba;
      }
    }
  }
}

// visibly hidden
.clipped {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
