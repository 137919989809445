@import './common.less';

// default styles

.card-tile {
  &__cards {
    padding: 16px 0 0;
  }

  &__card {
    box-sizing: border-box;
    padding: 24px 0 16px;
    display: flex;

    &-left-index {
      height: 48px;
      line-height: 48px;
      min-width: 48px;
      width: 48px;
      font-size: @font-size-medium;
      font-weight: @font-weight-bold;
      margin-right: 16px;
    }

    &-title {
      .typography-medium();
      font-weight: @font-weight-bold;
    }

    &-details {
      .typography-regular();
    }
  }
}

// vertical styles
.card-tile-vertical {
  &__card {
    width: 100%;
    display: flex;
    align-items: center;
  }
}