@import './common.less';

.parts-scheduling {
  &__left,
  &__right {
    margin: 0 16px;
    padding: 16px 0;
  }

  &__right {
    padding-top: 0;
  }

  &__help {
    padding: 24px 0;
    border-top: 1px solid var(--color-background-tertiary);
  }

  &__help h4 {
    .typography-medium-bold();
    margin: 0 0 10px 0;
  }

  &__image {
    height: 100px;
    margin-bottom: 16px;
  }
  &__service-operation,
  &__provider {
    display: flex;
  }

  &__provider {
    margin-top: 0;
  }
  &__provider-text {
    .typography-small();
  }
  &__faq-deatils li {
    margin: 8px 0 24px 0;
  }

  .ctas {
    padding: 16px;
  }

  &__content {
    height: ~"calc(100vh - 145px)";
    height: calc(100 * var(--pivh) - 145px);
    overflow-y: scroll;
  }

  &__operation-section {
    padding-bottom: 16px;
    summary.details__summary {
      color: var(--color-foreground-primary);
      font-weight: bold;
      padding-left: 0;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      justify-content: space-between;
    }
    p {
      margin: 0;
    }
    summary.details__summary:focus, summary.details__summary:hover {
      color: var(--color-foreground-primary);
    }
  }

  .installer-card-no-radio__star-rating {
    flex-wrap: wrap;
    .installer-card-no-radio__feature {
      flex-basis: 100%;
      margin-left: 0;
      padding: 4px 0;
    }
  }

  .screen-loading {
    padding: 0 16px 16px;
    height: 80%;
    &__content-top {
      .skeleton__textbox {
        height: 165px;
      }
    }
    &__content-main {
      padding-top: 32px;
    }
    &__content-left {
      .skeleton {
        padding-bottom: 32px;
      }
    }
  }
}