.find-area {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  &__main {
    padding-bottom: 15px;
  }

  &__installation-label {
    margin: 0;
    padding: 0;
  }

  &__drawer-body {
    padding-right: 16px
  }

  .drawer-dialog__window {
    max-height: 80%;
  }
}

.find-area-no-drawer {
  .radius {
    margin-bottom: 16px;
  }
  .bottom {
    margin-bottom: 16px;
    button {
      min-height: 48px;
    }
  }
}
.find-area-no-drawer.stickyCta {
  .radius {
    height: 250px;
    overflow-y: scroll;
    margin-bottom: 60px;
  }
  .bottom {
    position: absolute;
    bottom: 0;
    width: 90%;
  }
}
.drawer-dialog__window--expanded {
  .find-area-no-drawer.stickyCta {
    .radius {
      height: auto;
      overflow-y: auto;
    }
  }
}
