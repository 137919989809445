@import './common.less';

.installer-list {
  ul.toggle-button-group li {
    width: 98%;
  }

  // skin override

  button.toggle-button {
    width: 100%;
  }
}