.vas-warranty-interstitial-v2 {
  padding: 0;
  h2 {
    margin: 0 0 24px 0;
    font-size: 1rem;
    font-weight: bold;
  }
  &__vasform {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__label {
    font-weight: bold;
    font-size: 1rem;
    line-height: 24px;
    margin-bottom: 0.25rem;
  }
  &__secondaryLabel {
    line-height: 20px;
  }
  &__tertiaryLabel {
    margin-bottom: 0.25rem;
  }
  &__err {
    display: none;
    .vas-messsage {
      // overide component style
      padding: 16px 0;
    }
  }
  &__err.show {
    display: flex;
  }
  &__cta {
    margin: 2rem 0;
    button.btn {
      margin: 0.25rem 0;
    }
  }
}
