.fullscreen-dialog__header {
  justify-content: flex-end;
}
#vault-wrapper {
  margin: -72px -24px 0px -24px;
  &.vault-enrollment {
    .vault-header {
      padding: 40px 30px;
    }
  }
  &.vault-enrollment-success {
    .vault-header {
      padding: 40px 30px 0px 30px;
    }
  }
  .vault-header {
    .t-st-section {
      &--title {
        font-size: 30px;
        line-height: 40px;
        font-weight: bold;
        margin-bottom: 24px;
      }
      &--subtitle {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  .vault-sections {
    margin: 40px 30px;
    h3 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 30px;
      line-height: 32px;
    }
    .vault-section {
      margin-bottom: 40px;
      &--content {
        .t-st-section {
          margin-bottom: 30px;
          &--header {
            font-size: 30px;
            line-height: 40px;
            font-weight: bold;
          }
          &--title {
            font-size: 16px;
            line-height: 24px;
            font-weight: bold;
            margin-bottom: 8px;
          }
          &--subtitle {
            font-size: 14px;
            line-height: 20px;
          }
          &.template-steps,
          &.template-steps-with-icon {
            display: flex;
            .t-st-section--title,
            .t-st-section--icon {
              width: 48px;
              height: 48px;
              border-radius: 50%;
              background: #f7f7f7;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
              margin-right: 16px;
              margin-bottom: 0px;
            }
            .t-st-section--subtitle {
              display: flex;
              align-items: center;
            }
          }
          &.template-steps-with-icon {
            .t-st-section--icon {
              background: #c5e5fb;
              svg.icon {
                fill: #2b0eaf;
              }
            }
          }
          &.template-block-with-bg {
            padding: 16px;
            border-radius: 6px;
            .t-st-section--header {
              margin-bottom: 8px;
            }
            .t-st-section--subtitle {
              margin-bottom: 4px;
            }
          }
        }
      }
    }
  }
  .vault-learnmore {
    margin-bottom: 28px;
    a {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .vault-consent {
    display: flex;
    margin-bottom: 20px;
    &--checkbox {
      margin-right: 8px;
    }
    &--content {
      font-size: 12px;
      line-height: 16px;
    }
  }
  .vault-cta {
    &--primary {
      padding-bottom: 20px;
      max-width: 340px;
      margin: auto;
    }
    &--secondary {
      max-width: 340px;
      margin: auto;
    }
    button {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
