.vas-warranty-interstitial-m {
  padding: 0.25rem 1rem 0.25rem 1rem;
  font-size: 0.875rem;

  .vas-interstitial-header {
    &-text {
      font-size: 1.25rem;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: 'Market Sans';
    }
  }

  .prod-item-card {
    padding: 0.75rem 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    &-left {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;

      &__img {
        img {
          min-width: 3rem;
          max-width: 6rem;
        }
      }
      &__title {
        align-self: center;
        margin-left: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
    }
    &__price {
      span {
        font-size: 1rem !important;
        font-weight: bold;
      }
      margin-left: 1.5rem;
      align-self: center;
    }
  }

  .divider {
    margin: 0.5rem 0;
    border-top: 1px solid #e1e1e1;
  }

  .vas-interstitial-items {
    margin-top: 0.75rem;

    .heading {
      span {
        font-size: 1.1rem;
        font-weight: bold;
      }
    }

    .vas-interstitial-item {
      margin: 1rem 0.2rem;
      &-header {
        &-main {
          display: flex;
          justify-content: space-between;
          &--title {
            font-size: 0.95rem;
            font-weight: bold;
          }
          &--price {
            font-size: 0.95rem;
            font-weight: bold;
          }
        }
        &--provider {
          display: block;
          margin-left: 0.75rem;
          font-size: 0.8rem;
          color: #767676;
        }
      }

      &-body {
        margin: 0.5rem 0.1rem 0 0.1rem;

        &__details {
          padding-left: 1.65rem;
          list-style-type: disc;
          font-size: 0.78rem;

          span {
            color: #111820;
          }
        }
        &-actions {
          margin: 0.5rem 0;
          font-family: 'Market Sans';

          button:disabled {
            opacity: 0.7;
          }

          button.btn.btn--fluid {
            border-radius: 3px;
            border-style: solid;
            border-width: 1px;
            font-family: inherit;
            font-size: 16px;
            height: 40px;
            margin-bottom: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
            padding: 0 3rem;
            white-space: nowrap;
            font-weight: 450;

            &.btn--primary {
              background-color: #0654ba;
              border-color: #0654ba;
            }

            &.btn--secondary {
              background-color: #fff;
              border-color: #ccc;
              color: #0654ba;
            }
          }
        }

        .learn-more {
          margin: 0.75rem 0;
          text-decoration: underline;

          a,
          a:focus,
          a:visited {
            color: #3665f3;
          }
        }
      }
    }
  }
}
