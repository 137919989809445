@import './common.less';

.parts-installation {
  .parts-header {
    padding: 0 16px;
  }
  .sticky_footer {
    box-shadow: 0 -4px 4px #0000000f;
    position: fixed;
    background-color: var(--color-background-primary);
    bottom: env(safe-area-inset-bottom);
    width: ~"calc(100vw)";
    height: 80px;
    .ctas {
      position: revert;
      button {
        min-height: 48px;
      }
    }
  }
}
