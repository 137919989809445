@import '~@ebay/skin/less.less';
@import '~@ebay/skin/tokens.css';

.sort {
  text-align: right;

  button.sort__icon {
    min-width: 100px;
    height: 30px;
    background-color: transparent;
    color: var(--color-neutral-900);

    svg {
      padding-right: 5px;
    }
  }

  &__drawer-body {
    text-align: left;

    div.listbox__option[role="option"] {
      padding: 8px 0;
    }
  }
}

.sort-no-drawer__radio-field {
  padding: 16px 0;

  label.field__label {
    padding: 0 16px;

    .typography-medium();
  }
}
