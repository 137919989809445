.vas-messsage {
  display: flex;
  // background-color: #F1F8FE; // should be set by parent component style
  // margin: 20px 0 28px 0; // should be set by parent component style
  padding: 16px;
  .svg-icon {
    // prevent icon shrink when long text,
    // note: set 'width: 24px' does not work
    min-width: 24px;
    max-width: 24px;
    align-items: center;
    display: flex;
    margin-right: 16px;
    align-self: center;
    svg {
      // fix ios device safari browser
      width: 24px;
      height: 24px;
    }
  }
  #icon-attention-filled-16 {
    fill: #e0103a;
  }
  #icon-information-filled-16 {
    fill: #3665f3;
  }
  .vas-messsage-text {
    font-size: 0.9rem;
  }
}
