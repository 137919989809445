@import './common.less';

/** MOBILE **/
.map {
  @arrow-size: 8px;

  &__root {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
  }

  &__google-map {
    height: 100%;
  }

  &__marker > input&__input:checked + label.tires-map__container::after {
    border-top: @arrow-size solid var(--color-blue-500);
  }

  &__container {
    max-width: 99px;
    padding: 8px;

    &::after {
      border-left: @arrow-size solid transparent;
      border-right: @arrow-size solid transparent;
      border-top: @arrow-size solid var(--color-background-primary);
      content: '';
      left: calc(50% - @arrow-size/2);
      position: absolute;
      top: 100%;
    }
  }

  .fluid() {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1;
    display: block;
  }

  &__label {
    .fluid();
  }

  &__accessory-label-0 {
    .fluid();
  }
}

.arrow-tooltip {
  .map {
    &__label,
    &__accessory-label-0 {
      display: none;
    }

    &__star-rating-average {
      height: auto;
    }
  }
}