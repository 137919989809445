@import './common.less';

.how-works {
  margin: 16px 0;

  &__title {

    .typography-small();

    margin-bottom: 8px;
  }

  &__learnMore {
    .typography-small-bold();
  }
}
