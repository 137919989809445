@import './common.less';
.introduction-header.vas-header {
  padding: 0 16px;
}
.parts-introduction {
  .introduction {
    &__top-content {
      height: 240px;
      img {
        width: 80%;
      }
    }

    &__bottom-content {
      overflow-y: scroll;
      height: calc(100vh - 415px);
      height: calc(100 * var(--pivh) - 415px);
      padding: 24px 16px 0;
      .card-tile__label > h2 {
        .typography-giant-2();
      }
      .card-tile__cards {
        padding: 24px 0 0;
        .card-tile__card {
          padding: 0 0 16px 0;
        }
      }
    }

    &__footer {
      padding: 0 16px;
      &__sec-labels {
        padding-top: 24px;
        .ux-textspans--PSEUDOLINK {
          color: var(--color-foreground-primary);
        }
      }
      .ctas {
        padding: 24px 0;
      }
      .ctas > a {
        width: 100%;
      }
    }
    .sticky_footer {
      height: 130px;
    }
  }
  .screen-loading {
    padding: 60px 16px;
    height: 90%;
    &__content-main {
      flex-direction: column;
    }
    &__content-left {
      .skeleton__image {
        height: 380px;
      }
    }
    &__content-right {
      .skeleton {
        padding-bottom: 16px;
      }
    }
  }
}